import {
  Slide,
  ToastContainer as ToastifyToastContainer,
} from "react-toastify";

const ToastContainer = () => {
  return (
    <ToastifyToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      icon={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
    />
  );
};

export default ToastContainer;
