import { QueryCache, QueryClient } from "@tanstack/react-query";
import userQueryKeys from "@modules/user/userQueryKeys";
import { HTTPError } from "ky";
import Router from "next/router";
import adminQueryKeys from "@modules/admin/user/adminQueryKeys";

export const getServerSideQueryClient = () => {
  return new QueryClient();
};

export const isUnauthorizedError = (error: unknown) =>
  error && error instanceof HTTPError && error.response.status === 401;

export const getClientSideQueryClient = () => {
  const qc = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: unknown, query) => {
        if (!query.meta?.overrideDefaultErrorHandling) {
          if (isUnauthorizedError(error)) {
            qc.removeQueries({ queryKey: userQueryKeys.all });
            qc.removeQueries({ queryKey: adminQueryKeys.all });

            if (Router.pathname.startsWith("/admin")) {
              // prevent a double replace and messing up the from query (queryClients + useAuthRedirect)
              if (!Router.pathname.startsWith("/admin/login")) {
                Router.replace({
                  pathname: "/admin/login",
                  query: { from: Router.asPath },
                });
              }
            } else {
              Router.replace({
                pathname: "/",
                query: { from: Router.asPath },
              });
            }
          }
        }
      },
    }),
  });

  qc.setDefaultOptions({
    queries: {
      staleTime: 5000,
      retry: false,
    },
    mutations: {
      onError: (error: unknown) => {
        if (isUnauthorizedError(error)) {
          qc.removeQueries({ queryKey: userQueryKeys.all });
        }
      },
    },
  });

  return qc;
};
