import {
  ApplicationInsights,
  ITelemetryItem,
  ITelemetryPlugin,
} from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  AppInsightsErrorBoundary,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";

let clientAppInsights: ApplicationInsights;
let reactPlugin: ReactPlugin;

const isBrowser = typeof window !== "undefined";

const getClientAppInsights = () => {
  const connectionString =
    process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTIONSTRING;
  const appInsightsCloudRole =
    process.env.NEXT_PUBLIC_APP_INSIGHTS_CLIENT_CLOUD_ROLE;

  if (!connectionString) {
    throw new Error("Application insights connection string not provided");
  }

  const telemetryInitializer = (envelope: ITelemetryItem) => {
    if (!envelope.tags) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    envelope.tags["ai.cloud.role"] = appInsightsCloudRole;
  };

  const initialize = () => {
    if (!isBrowser && clientAppInsights) return;

    reactPlugin = new ReactPlugin();
    clientAppInsights = new ApplicationInsights({
      config: {
        connectionString,
        disableFetchTracking: false,
        // Cast to `ITelemetryPlugin` due to typing bug
        // https://github.com/microsoft/applicationinsights-react-js/issues/12#issuecomment-1201874674
        extensions: [reactPlugin as unknown as ITelemetryPlugin],
        disablePageUnloadEvents: ["unload"],
      },
    });

    clientAppInsights.loadAppInsights();
    clientAppInsights.addTelemetryInitializer(telemetryInitializer);
  };

  return { clientAppInsights, initialize, reactPlugin };
};

export {
  getClientAppInsights,
  AppInsightsErrorBoundary,
  useAppInsightsContext,
};
