let locales = ["en", "fr"];
if (
  process.env.NEXT_PUBLIC_ENV_NAME === "qa" ||
  process.env.NEXT_PUBLIC_ENV_NAME === "local"
) {
  locales = ["en", "fr", "zu"];
}

module.exports = {
  i18n: {
    defaultLocale: "en",
    locales,
  },
  reloadOnPrerender: process.env.NODE_ENV === "development",
  serializeConfig: false,
  react: {
    // https://github.com/i18next/react-i18next/issues/1323
    transWrapTextNodes: "span",
  },
};
